import toast, { Toaster } from 'react-hot-toast'
import './index.scss'

import copyIcon from '@static/images/copyIcon.svg'

const faqData = [
    {
        title: 'What is Tricorn Bridge?',
        description:
            'Tricorn Bridge is a cutting-edge cross-chain bridge solution designed to enhance interoperability between different blockchain networks. It allows for secure and efficient transfers of assets across multiple blockchains, supporting both EVM and non-EVM chains.',
    },
    {
        title: 'Which blockchains are supported by Tricorn Bridge?',
        description:
            'Tricorn Bridge supports Ethereum, Polygon, and Concordium blockchains. We are continuously working to integrate more chains to broaden our network.',
    },
    {
        title: 'How secure is Tricorn Bridge?',
        description:
            'Tricorn Bridge prioritizes security with advanced measures to protect assets and ensure transaction safety. Our smart contracts have been audited by Halborn and Hacken – industry-leading security firms – to provide our users with a reliable service.',
    },
    {
        title: 'How does Tricorn Bridge work?',
        description:
            'Tricorn Bridge operates through a decentralized application (dApp) which involves two main components: a Router Smart Contract on the sending chain and a matching Router Smart Contract on the receiving chain.',
    },
    {
        title: 'What are the fees associated with using Tricorn Bridge?',
        description:
            'Every transaction comes with a flexible destination network fee (gas fee) and a stable commission percentage for Tricorn Bridge services. For more detailed information, please use our estimated fee calculator on the bridge app.',
    },
    {
        title: 'How long do cross-chain transfers take?',
        description:
            'Transfer times can vary based on the underlying blockchainʼs processing speed and network congestion at the time of your transaction. Typically, transfers are completed within a few minutes.',
    },
    {
        title: 'How can I start using Tricorn Bridge?',
        description:
            'To start using Tricorn Bridge, visit our website and click ‘Launch App’ or head right over to https://bridge.tricorn.network/. You will need to connect your crypto wallets, choose the assets you wish to send, and specify the destination chain. Detailed step-by-step instructions are available on our platform.',
    },
    {
        title: 'Сan I use Tricorn Bridge on mobile devices?',
        description:
            'At the moment, the only way to use Tricorn Bridge on a mobile device is through the built-in MetaMask browser. To access it, simply install MetaMask on your device and switch to browser view. Note: In the MetaMask browser, you can only make transactions from EVM-supported networks.',
    },
]

const bridgeAdresses = [
    {
        token: 'Concordium',
        address: '9427',
        link: 'https://ccdscan.io/?dcount=1&dentity=contract&dcontractAddressIndex=9427&dcontractAddressSubIndex=0',
    },
    {
        token: 'Polygon',
        address: '0xF398578e812777849a0DC8461faF75E1a8Fe4ED4',
        link: 'https://polygonscan.com/address/0xf398578e812777849a0dc8461faf75e1a8fe4ed4',
    },
    {
        token: 'Ethereum',
        address: '0x67a5Aa90EFB317e0cA2c1A68abF5e67C1FB4822c',
        link: 'https://etherscan.io/address/0x67a5Aa90EFB317e0cA2c1A68abF5e67C1FB4822c',
    },
]

const polygonTokens = [
    {
        token: 'tCCD',
        address: '0x5799221DFdf8544F0fBAB1067dC131AC2C52525B',
    },
    {
        token: 'tETH',
        address: '0xf5db85b8bE1A1CBaa3e3756e4167EC21f9609BEe',
    },
]

const ethWrappedTokens = [
    {
        token: 'tCCD',
        address: '0x24e658560b4eb14eAaeA7e60D960752EC8de20a6',
    },
    {
        token: 'tMATIC',
        address: '0xdc019857b39E514a5BADc079748E0Fc216A29fb9',
    },
]

const ethTokens = [
    {
        token: 'USDT',
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    {
        token: 'USDC',
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    {
        token: 'UNI',
        address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    },
    {
        token: 'LINK',
        address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    },
    {
        token: 'MANA',
        address: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    },
    {
        token: 'AAVE',
        address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    },
    {
        token: 'DAI',
        address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    {
        token: 'VNXAU',
        address: '0x6d57B2E05F26C26b549231c866bdd39779e4a488',
    },
]
export const Questions: React.FC = () => {
    const handleCopyAddress = (address: string) => {
        toast.dismiss()
        navigator.clipboard.writeText(address)
        toast.success('Copied!')
    }

    return (
        <section className="faq">
            <Toaster />
            <div className="faq__wrapper">
                <h1 className="faq__title">Frequently Asked Questions</h1>
                <p className="faq__description">
                    Find out the answers to the most popular questions
                </p>
                {faqData.map((item, key) =>
                    <div key={key} className="faq__block">
                        <h2 className="faq__block__title">{item.title}</h2>
                        <p className="faq__block__description">
                            {item.description}
                        </p>
                    </div>
                )}

                <div className="faq__block">
                    <h2 className="faq__block__title">
                        What are the contract addresses of Tricorn Bridge?
                    </h2>
                    {bridgeAdresses.map((item, key) =>
                        <p
                            key={key}
                            className="faq__description faq__token-address"
                        >
                            <a
                                className="faq__block__link"
                                href={item.link}
                                target="_blank"
                            >
                                {item.token}: {item.address}
                            </a>

                            <img
                                loading="lazy"
                                src={copyIcon}
                                className="faq__block__copy-icon"
                                alt="copy icon"
                                onClick={() => handleCopyAddress(item.address)}
                            />
                        </p>
                    )}
                </div>

                <div className="faq__block">
                    <h2 className="faq__block__title">
                        What are the contract addresses of tokens deployed on
                        Tricorn Bridge?
                    </h2>
                    <p className="faq__block__subtitle">
                        Concordium – wrapped Tricorn tokens: <br />
                    </p>
                    <ul className="faq__block__list">
                        <li className="faq__block__list__punkt">
                            tUSDT – 9451
                        </li>
                        <li className="faq__block__list__punkt">
                            tUSDC – 9450
                        </li>
                        <li className="faq__block__list__punkt">
                            tMATIC – 9443
                        </li>
                        <li className="faq__block__list__punkt">tETH – 9444</li>
                        <li className="faq__block__list__punkt">
                            tAAVE – 9445
                        </li>
                        <li className="faq__block__list__punkt">tDAI – 9446</li>
                        <li className="faq__block__list__punkt">
                            tLINK – 9447
                        </li>
                        <li className="faq__block__list__punkt">
                            tMANA – 9448
                        </li>
                        <li className="faq__block__list__punkt">tUNI – 9449</li>
                        <li className="faq__block__list__punkt">
                            tVNXAU – 9452
                        </li>
                    </ul>
                    <p className="faq__block__subtitle">
                        Polygon – wrapped Tricorn tokens:
                    </p>
                    {polygonTokens.map((item, key) =>
                        <p
                            key={key}
                            className="faq__description faq__token-address"
                        >
                            {item.token} - {item.address}
                            <img
                                loading="lazy"
                                src={copyIcon}
                                className="faq__block__copy-icon"
                                alt="copy icon"
                                onClick={() => handleCopyAddress(item.address)}
                            />
                        </p>
                    )}

                    <p className="faq__block__subtitle">
                        Ethereum – wrapped Tricorn tokens:
                    </p>
                    {ethWrappedTokens.map((item, key) =>
                        <p
                            key={key}
                            className="faq__description faq__token-address"
                        >
                            {item.token} - {item.address}
                            <img
                                loading="lazy"
                                src={copyIcon}
                                className="faq__block__copy-icon"
                                alt="copy icon"
                                onClick={() => handleCopyAddress(item.address)}
                            />
                        </p>
                    )}
                    <p className="faq__block__subtitle">
                        Ethereum – supported tokens:
                    </p>
                    {ethTokens.map((item, key) =>
                        <p
                            key={key}
                            className="faq__description faq__token-address"
                        >
                            {item.token} - {item.address}
                            <img
                                loading="lazy"
                                src={copyIcon}
                                className="faq__block__copy-icon"
                                alt="copy icon"
                                onClick={() => handleCopyAddress(item.address)}
                            />
                        </p>
                    )}
                </div>
            </div>
        </section>
    )
}
